import { APIM, ElevatedScope, NotificationUrl } from "src/common/constants";
import { Channel, EasyCallReportConfiguration, Team } from "src/common/interfaces";
import { Providers, ProviderState, prepScopes } from '@microsoft/mgt-element';
import axios, { AxiosError, AxiosResponse } from "axios";

export class ConfigurationService {
    constructor(access_token: string) {
        if (access_token !== null) {
            if (access_token.startsWith("Bearer ")) {
                this.accessToken = access_token;
            }
            else {
                this.accessToken = "Bearer " + access_token;
            }
        }
    }

    accessToken: string;

    async timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async GetTeamInformation(selectedTeams: string): Promise<Team> {

        if (selectedTeams === undefined || selectedTeams === null)
            return null;

        if (Providers.globalProvider.state === ProviderState.SignedIn) {
            try {
                const data = Providers.globalProvider.graph.client.api('https://graph.microsoft.com/v1.0/teams/' + encodeURIComponent(selectedTeams)).get();

                if (data) {
                    return data;
                }
            } catch (e) {
                throw new AxiosError(e);
            };
        }

        return null;
    }

    async GetChannelInformation(selectedTeams: string, selectedChannel: string): Promise<Channel> {
        if (selectedTeams === undefined || selectedTeams === null)
            return null;

        if (selectedChannel === undefined || selectedChannel === null)
            return null;

        if (Providers.globalProvider.state === ProviderState.SignedIn) {
            try {
                const data = await Providers.globalProvider.graph.client.api('https://graph.microsoft.com/v1.0/teams/' + encodeURIComponent(selectedTeams) + '/channels/' + encodeURIComponent(selectedChannel)).get();

                if (data) {
                    return data;
                }
            } catch (e) {
                throw new AxiosError(e);
            };
        }

        return null;
    }

    async IsTeamsAdmin(): Promise<boolean> {

        if (Providers.globalProvider.state === ProviderState.SignedIn) {
            try {
                const data = await Providers.globalProvider.graph.client.api(APIM + 'easycallreport/admin/callqueues').middlewareOptions(prepScopes(ElevatedScope)).get();

                if (data) {
                    return true;
                }
            } catch {
                return false;
            };
        } else {
            return false;
        }

        return false;
    }

    async getCallQueues(): Promise<any> {

        if (Providers.globalProvider.state === ProviderState.SignedIn) {
            try {
                return await Providers.globalProvider.graph.client.api(APIM + 'easycallreport/admin/callqueues').middlewareOptions(prepScopes(ElevatedScope)).get();
            } catch (e) {
                throw new AxiosError(e);
            }
        } else {
            return null;
        }
    }

    async getAutoAttendants(): Promise<any> {

        if (Providers.globalProvider.state === ProviderState.SignedIn) {
            try {
                return await Providers.globalProvider.graph.client.api(APIM + 'easycallreport/admin/autoattendants').middlewareOptions(prepScopes(ElevatedScope)).get();
            } catch (e) {
                throw new AxiosError(e);
            };
        }

        return null;
    }

    async GetConfiguration(): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.get(APIM + 'easycallreport/admin/configuration', {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json-patch+json'
                }
            });

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        };
    }

    async UpdateFullConfiguration(Config: EasyCallReportConfiguration): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/admin/configuration', Config, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json-patch+json'
                }
            });

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async UpdateConfiguration(Property: string, Value: string): Promise<EasyCallReportConfiguration> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/admin/configuration', JSON.parse("{\"" + Property + "\": " + Value + "}"), {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json-patch+json'
                }
            });

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async SendConfigurationChangeNotification(configChange: { tenantId: string, channelId: string}): Promise<AxiosResponse> {
        try {
            const response = await axios.patch(APIM + 'easycallreport/notifyConfiguration', configChange, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async RenewSubscription(): Promise<any> {
        try {
            const response = await axios.post(APIM + 'easycallreport/register', {
                "changeType": "created",
                "notificationUrl": NotificationUrl,
                "resource": "/communications/callRecords",
                "clientState": "TeamsCallReceiver"
            }, {
                headers: {
                    'Authorization': this.accessToken,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }
}
import axios, { AxiosError } from "axios";
import { APIM } from "src/common/constants";
import { Customer, Subscription } from "src/common/interfaces";

export class SubscriptionService {
    constructor(access_token?: string) {
        if (access_token !== undefined && access_token !== null) {
            if (access_token.startsWith("Bearer ")) {
                this.access_token = access_token;
            }
            else {
                this.access_token = "Bearer " + access_token;
            }
        }
    }

    access_token: string;

    getSubscriptionStatus(status_id: number): string {
        switch (status_id) {
            case 0:
                return "Unknown";
            case 1:
                return "Pending Confirmation";
            case 2:
                return "Pending Activation";
            case 3:
                return "Active";
            case 4:
                return "Suspended";
            case 5:
                return "Cancelled";
            case 6:
                return "Purchased";
            default:
                return "Unknown";
        }
    }

    getSubscriptionProductTitle(offer_id: string): string {
        switch (offer_id) {
            case "easycallreport_dev-preview":
                return "Easy Call Report";
            case "easycallreport_dev":
                return "Easy Call Report";
            case "easycallreport-preview":
                return "Easy Call Report";
            case "easycallreport":
                return "Easy Call Report";
            case "TeamsEasyCallReport-Standard-CHF-Yearly":
                return "Easy Call Report (deprecated)";
            case "TeamsEasyCallReport-Standard-EUR-Yearly":
                return "Easy Call Report (deprecated)";
            default:
                return "Unknown";
        }
    }

    getSubscriptionProductPlan(plan_id: string): string {
        switch (plan_id) {
            case "ecr_basic":
            case "basic":
                return "Basic";
            case "pro":
                return "Professional";
            case "enterprise":
                return "Enterprise";
            case "TeamsEasyCallReport-Standard-CHF-Yearly":
                return "Basic";
            case "TeamsEasyCallReport-Standard-EUR-Yearly":
                return "Basic";
            default:
                return "Unknown";
        }
    }

    async getSubscriptionFromToken(store_token: string): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions/landing?token=' + encodeURIComponent(store_token), {
            method: 'GET'
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async postNewSubscription(store_token: string, subscription: Subscription): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions', {
            method: 'POST',
            headers: {
                'Authorization': store_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subscription)
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async patchSubscription(subscriptionId: string, subscription: Subscription): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions/' + subscriptionId, {
            method: 'PATCH',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subscription)
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async getSubscriptions(): Promise<Subscription[]> {
        const response = await fetch(APIM + 'subscriptions', {
            method: 'GET',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res ?? null;
        }

        return null;
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        try {
            const response = await axios.get(APIM + 'subscriptions/' + subscriptionId, {
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            })

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    // Checks for a valid license and return seat count
    // async getEasyCallReportSeatCount(subscriptions?: Subscription[]): Promise<number> {
    //     let seatCount:number = 0;

    //     if(subscriptions === undefined && subscriptions !== null) {
    //         subscriptions = await this.getSubscriptions();
    //     }

    //     subscriptions.forEach((subscription) => {
    //         if(subscription.offer_id.includes("easycallreport") === true) {
    //             seatCount = seatCount + subscription.seatQuantity;
    //         }
    //     });

    //     seatCount = seatCount * 5; // Multiply with 5, because we offer 5 call queues per seat

    //     return seatCount;
    // }

    getEasyCallReportSeatCount(subscriptions: Subscription[]): number {
        let seatCount: number = 0;

        subscriptions.forEach((subscription) => {
            if (subscription.offer_id.includes("easycallreport") === true) {
                seatCount = seatCount + subscription.seatQuantity;
            }
        });

        seatCount = seatCount * 5; // Multiply with 5, because we offer 5 call queues per seat

        return seatCount;
    }

    isEasyCallReportLicensed(subscriptions: Subscription[]): boolean {
        let isLicensed: boolean = false;

        subscriptions.forEach((subscription) => {
            if (subscription.offer_id.includes("easycallreport") === true) {
                isLicensed = true;
            }
        });

        return isLicensed;
    }


    // Old chargebee APIs

    async getCustomer(): Promise<Customer> {
        const response = await fetch(APIM + 'licenses/customers', {
            method: 'GET',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res.customer ?? null;
        }

        return null;
    }

    async getCheckoutPage(CustomerId: string, Quantity: number) {
        const response = await fetch(APIM + 'licenses/checkout?customer[' + CustomerId + ']&subscription_items[item_price_id][0]="TeamsEasyCallReport-Standard"&subscription_items[quantity][0]=' + Quantity, {
            method: 'GET',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res;
        }

        return null;
    }
}
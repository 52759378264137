import 'react-app-polyfill/stable'
import 'core-js'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import './scss/style.scss'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './i18n';
import Page404 from './views/pages/page404/Page404'
import AuthenticatedLayout from './layout/AuthenticatedLayout'
import AnonymousLayout from './layout/AnonymousLayout'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const EasyCallReport = React.lazy(() => import('./views/easycallreport/EasyCallReport'))
const SubscriptionManager = React.lazy(() => import('./views/subscriptions/SubscriptionManager'))
const SubscriptionOnboarding = React.lazy(() => import('./views/subscriptions/Onboarding'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route element={<AuthenticatedLayout />}>
            <Route path="/" exact={true} name="Home" element={<Dashboard />} />
            <Route path="/dashboard" name="Home" element={<Dashboard />} />
            <Route path="/easycallreport" exact={true} name="Easy Call Report" element={<EasyCallReport />} />
            <Route path="/subscriptions" exact={true} name="Subscription Manager" element={<SubscriptionManager />} />
            <Route path="/subscriptions/:subscriptionId" exact={true} name="Subscription Manager" element={<SubscriptionManager />} />
          </Route>
          <Route element={<AnonymousLayout />}>
            <Route path="/subscriptions/onboarding" exact={true} name="Subscription Onboarding" element={<SubscriptionOnboarding />} />
            <Route path="/store/landing" exact={true} name="Microsoft Marketplace Landing" element={<SubscriptionOnboarding />} />
            <Route path="*" name="404" element={<Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

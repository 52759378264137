import { Providers } from "@microsoft/mgt-element";
import { useEffect, useState } from "react";
import { Scopes } from "src/common/constants";

function GraphToken(): [string] {
    const [graphToken, setGraphToken] = useState("");

    useEffect(() => {
        const updateState = async () => {
            const provider = Providers.globalProvider;

            try {
                if (provider) {
                    const token = await Providers.globalProvider.getAccessToken({
                        scopes: Scopes,
                    });
                    setGraphToken(token);
                }
            } catch {
                setGraphToken(null);
            }
        };

        Providers.onProviderUpdated(updateState);
        updateState();

        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        }
    }, []);

    return [graphToken];
}

export default GraphToken